// Needed CSS for the navbar
@import 'bulma-simple.css'

$black:        #0B3954 !default
$black-bis:    hsl(0, 0%, 7%) !default
$black-ter:    hsl(0, 0%, 14%) !default
$black-light:  #5a5e66

$grey-darker:  hsl(0, 0%, 21%) !default
$grey-dark:    hsl(0, 0%, 29%) !default
$grey:         hsl(0, 0%, 48%) !default
$grey-light:   #dcdfe6 !default
$grey-lighter: hsl(0, 0%, 86%) !default
$grey-lightest: hsl(0, 0%, 93%) !default
$grey-background: #f6f8fa

$hr-background-color: #dcdfe6 !default
$hr-height: 1px
$hr-margin: 2.25rem 0

$white-ter:    hsl(0, 0%, 96%) !default
$white-bis:    hsl(0, 0%, 98%) !default
$white:        hsl(0, 0%, 100%) !default

$orange:       hsl(14,  100%, 53%) !default
$yellow:       hsl(48,  100%, 67%) !default
$green:        hsl(141, 53%,  53%) !default
$turquoise:    hsl(171, 100%, 41%) !default
$cyan:         hsl(204, 71%,  53%) !default
$blue:         hsl(217, 71%,  53%) !default
$purple:       hsl(271, 100%, 71%) !default
$red:          hsl(348, 86%, 61%) !default

$primary:      #0B3954
$contrast:     #2d52a3
$text:         #333438
$link:         $primary


$dark-blue: #0B3954

$navbar-item-hover-background-color: white

$tabs-link-active-border-bottom-color: $primary
$tabs-link-active-color: $primary
$tabs-link-hover-color: $contrast
$tabs-link-hover-border-bottom-color: $contrast
$tabs-link-color: $grey
$tabs-border-bottom-width: 2px

$body-family: 'Manrope', sans-serif !default
$family-sans-serif: 'Manrope', sans-serif !default
$family-monospace: monospace !default
$render-mode: optimizeLegibility !default

$size-1: 3rem !default
$size-2: 2.5rem !default
$size-3: 2rem !default
$size-4: 1.5rem !default
$size-5: 1.25rem !default
$size-6: 1rem !default
$size-7: 0.75rem !default

$weight-normal: 300 !default

$radius-small: 0px !default
$radius: 0px !default
$radius-large: 6px !default
$radius-rounded: 290486px !default

$input-radius: 0px

$section-padding: 1.5rem

$card-radius: 0rem

// --START-- copy from Bulma to stop having errors

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px !default
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default
$widescreen-enabled: true !default
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap) !default
$fullhd-enabled: true !default
$breakpoints: ("mobile": ("until": $tablet), "tablet": ("from": $tablet), "tablet-only": ("from": $tablet, "until": $desktop), "touch": ("from": $desktop), "desktop": ("from": $desktop), "desktop-only": ("from": $desktop, "until": $widescreen), "until-widescreen": ("until": $widescreen), "widescreen": ("from": $widescreen), "widescreen-only": ("from": $widescreen, "until": $fullhd), "until-fullhd": ("until": $fullhd), "fullhd": ("from": $fullhd)) !default

// colors
$primary: $turquoise !default

$info: $cyan !default
$success: $green !default
$warning: $yellow !default
$danger: $red !default

$light: $white-ter !default
$dark: $grey-darker !default

$scheme-main: $white !default
$scheme-main-bis: $white-bis !default
$scheme-main-ter: $white-ter !default
$scheme-invert: $black !default
$scheme-invert-bis: $black-bis !default
$scheme-invert-ter: $black-ter !default

$background: $white-ter !default

$border: $grey-lighter !default
$border-hover: $grey-light !default
$border-light: $grey-lightest !default
$border-light-hover: $grey-light !default


// mixins
=clearfix
  &::after
    clear: both
    content: " "
    display: table

=center($width, $height: 0)
  position: absolute
  @if $height != 0
    left: calc(50% - (#{$width} * 0.5))
    top: calc(50% - (#{$height} * 0.5))
  @else
    left: calc(50% - (#{$width} * 0.5))
    top: calc(50% - (#{$width} * 0.5))

=fa($size, $dimensions)
  display: inline-block
  font-size: $size
  height: $dimensions
  line-height: $dimensions
  text-align: center
  vertical-align: top
  width: $dimensions

=hamburger($dimensions)
  -moz-appearance: none
  -webkit-appearance: none
  appearance: none
  background: none
  border: none
  cursor: pointer
  display: block
  height: $dimensions
  position: relative
  width: $dimensions
  span
    background-color: currentColor
    display: block
    height: 1px
    left: calc(50% - 8px)
    position: absolute
    transform-origin: center
    transition-duration: $speed
    transition-property: background-color, opacity, transform
    transition-timing-function: $easing
    width: 16px
    &:nth-child(1)
      top: calc(50% - 6px)
    &:nth-child(2)
      top: calc(50% - 1px)
    &:nth-child(3)
      top: calc(50% + 4px)
  &:hover
    background-color: bulmaRgba(black, 0.05)
  // Modifers
  &.is-active
    span
      &:nth-child(1)
        transform: translateY(5px) rotate(45deg)
      &:nth-child(2)
        opacity: 0
      &:nth-child(3)
        transform: translateY(-5px) rotate(-45deg)

=overflow-touch
  -webkit-overflow-scrolling: touch

=placeholder
  $placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input'
  @each $placeholder in $placeholders
    &:#{$placeholder}-placeholder
      @content

=reset
  -moz-appearance: none
  -webkit-appearance: none
  appearance: none
  background: none
  border: none
  color: currentColor
  font-family: inherit
  font-size: 1em
  margin: 0
  padding: 0

// extends.sass
=arrow($color: transparent)
  border: 3px solid $color
  border-radius: 2px
  border-right: 0
  border-top: 0
  content: " "
  display: block
  height: 0.625em
  margin-top: -0.4375em
  pointer-events: none
  position: absolute
  top: 50%
  transform: rotate(-45deg)
  transform-origin: center
  width: 0.625em

%arrow
  +arrow

%reset
  +reset

// Responsiveness
=from($device)
  @media screen and (min-width: $device)
    @content

=until($device)
  @media screen and (max-width: $device - 1px)
    @content

=between($from, $until)
  @media screen and (min-width: $from) and (max-width: $until - 1px)
    @content

=mobile
  @media screen and (max-width: $tablet - 1px)
    @content

=tablet
  @media screen and (min-width: $tablet), print
    @content

=tablet-only
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px)
    @content

=touch
  @media screen and (max-width: $desktop - 1px)
    @content

=desktop
  @media screen and (min-width: $desktop)
    @content

=desktop-only
  @if $widescreen-enabled
    @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px)
      @content

=until-widescreen
  @if $widescreen-enabled
    @media screen and (max-width: $widescreen - 1px)
      @content

=widescreen
  @if $widescreen-enabled
    @media screen and (min-width: $widescreen)
      @content

=widescreen-only
  @if $widescreen-enabled and $fullhd-enabled
    @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px)
      @content

=until-fullhd
  @if $fullhd-enabled
    @media screen and (max-width: $fullhd - 1px)
      @content

=fullhd
  @if $fullhd-enabled
    @media screen and (min-width: $fullhd)
      @content

=breakpoint($name)
  $breakpoint: map-get($breakpoints, $name)
  @if $breakpoint
    $from: map-get($breakpoint, "from")
    $until: map-get($breakpoint, "until")
    @if $from and $until
      +between($from, $until)
        @content
    @else if $from
      +from($from)
        @content
    @else if $until
      +until($until)
        @content

=ltr
  @if not $rtl
    @content

=rtl
  @if $rtl
    @content

=ltr-property($property, $spacing, $right: true)
  $normal: if($right, "right", "left")
  $opposite: if($right, "left", "right")
  #{$property}-#{$normal}: $spacing

=ltr-position($spacing, $right: true)
  $normal: if($right, "right", "left")
  $opposite: if($right, "left", "right")
  #{$normal}: $spacing

// Miscellaneous
$easing: ease-out !default
$radius-small: 2px !default
$radius: 4px !default
$radius-large: 6px !default
$radius-rounded: 9999px !default
$speed: 86ms !default

// Placeholders
=unselectable
  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

=arrow($color: transparent)
  border: 3px solid $color
  border-radius: 2px
  border-right: 0
  border-top: 0
  content: " "
  display: block
  height: 0.625em
  margin-top: -0.4375em
  pointer-events: none
  position: absolute
  top: 50%
  transform: rotate(-45deg)
  transform-origin: center
  width: 0.625em

=block($spacing: $block-spacing)
  &:not(:last-child)
    margin-bottom: $spacing

=delete
  +unselectable
  -moz-appearance: none
  -webkit-appearance: none
  background-color: bulmaRgba($scheme-invert, 0.2)
  border: none
  border-radius: $radius-rounded
  cursor: pointer
  pointer-events: auto
  display: inline-block
  flex-grow: 0
  flex-shrink: 0
  font-size: 0
  height: 20px
  max-height: 20px
  max-width: 20px
  min-height: 20px
  min-width: 20px
  outline: none
  position: relative
  vertical-align: top
  width: 20px
  &::before,
  &::after
    background-color: $scheme-main
    content: ""
    display: block
    left: 50%
    position: absolute
    top: 50%
    transform: translateX(-50%) translateY(-50%) rotate(45deg)
    transform-origin: center center
  &::before
    height: 2px
    width: 50%
  &::after
    height: 50%
    width: 2px
  &:hover,
  &:focus
    background-color: bulmaRgba($scheme-invert, 0.3)
  &:active
    background-color: bulmaRgba($scheme-invert, 0.4)
  // Sizes
  &.is-small
    height: 16px
    max-height: 16px
    max-width: 16px
    min-height: 16px
    min-width: 16px
    width: 16px
  &.is-medium
    height: 24px
    max-height: 24px
    max-width: 24px
    min-height: 24px
    min-width: 24px
    width: 24px
  &.is-large
    height: 32px
    max-height: 32px
    max-width: 32px
    min-height: 32px
    min-width: 32px
    width: 32px

=loader
  animation: spinAround 500ms infinite linear
  border: 2px solid $grey-lighter
  border-radius: $radius-rounded
  border-right-color: transparent
  border-top-color: transparent
  content: ""
  display: block
  height: 1em
  position: relative
  width: 1em

=overlay($offset: 0)
  bottom: $offset
  left: $offset
  position: absolute
  right: $offset
  top: $offset

// navbar.sass
$navbar-background-color: $scheme-main !default
$navbar-box-shadow-size: 0 2px 0 0 !default
$navbar-box-shadow-color: $background !default
$navbar-height: 3.25rem !default
$navbar-padding-vertical: 1rem !default
$navbar-padding-horizontal: 2rem !default
$navbar-z: 30 !default
$navbar-fixed-z: 30 !default

$navbar-item-color: $text !default
$navbar-item-hover-color: $link !default
$navbar-item-hover-background-color: $scheme-main-bis !default
$navbar-item-active-color: $scheme-invert !default
$navbar-item-active-background-color: transparent !default
$navbar-item-img-max-height: 1.75rem !default

$navbar-burger-color: $navbar-item-color !default

$navbar-tab-hover-background-color: transparent !default
$navbar-tab-hover-border-bottom-color: $link !default
$navbar-tab-active-color: $link !default
$navbar-tab-active-background-color: transparent !default
$navbar-tab-active-border-bottom-color: $link !default
$navbar-tab-active-border-bottom-style: solid !default
$navbar-tab-active-border-bottom-width: 3px !default

$navbar-dropdown-background-color: $scheme-main !default
$navbar-dropdown-border-top: 2px solid $border !default
$navbar-dropdown-offset: -4px !default
$navbar-dropdown-arrow: $link !default
$navbar-dropdown-radius: $radius-large !default
$navbar-dropdown-z: 20 !default

$navbar-dropdown-boxed-radius: $radius-large !default
$navbar-dropdown-boxed-shadow: 0 8px 8px bulmaRgba($scheme-invert, 0.1), 0 0 0 1px bulmaRgba($scheme-invert, 0.1) !default

$navbar-dropdown-item-hover-color: $scheme-invert !default
$navbar-dropdown-item-hover-background-color: $background !default
$navbar-dropdown-item-active-color: $link !default
$navbar-dropdown-item-active-background-color: $background !default

$navbar-divider-background-color: $background !default
$navbar-divider-height: 2px !default

$navbar-bottom-box-shadow-size: 0 -2px 0 0 !default

$navbar-breakpoint: $desktop !default

=navbar-fixed
  left: 0
  position: fixed
  right: 0
  z-index: $navbar-fixed-z

.navbar
  background-color: $navbar-background-color
  min-height: $navbar-height
  position: relative
  z-index: $navbar-z
 
  & > .container
    align-items: stretch
    display: flex
    min-height: $navbar-height
    width: 100%
  &.has-shadow
    box-shadow: $navbar-box-shadow-size $navbar-box-shadow-color
  &.is-fixed-bottom,
  &.is-fixed-top
    +navbar-fixed
  &.is-fixed-bottom
    bottom: 0
    &.has-shadow
      box-shadow: $navbar-bottom-box-shadow-size $navbar-box-shadow-color
  &.is-fixed-top
    top: 0

html,
body
  &.has-navbar-fixed-top
    padding-top: $navbar-height
  &.has-navbar-fixed-bottom
    padding-bottom: $navbar-height

.navbar-brand,
.navbar-tabs
  align-items: stretch
  display: flex
  flex-shrink: 0
  min-height: $navbar-height

.navbar-brand
  a.navbar-item
    &:focus,
    &:hover
      background-color: transparent

.navbar-tabs
  +overflow-touch
  max-width: 100vw
  overflow-x: auto
  overflow-y: hidden

.navbar-menu
  display: none

.navbar-item,
.navbar-link
  color: $navbar-item-color
  display: block
  line-height: 1.5
  padding: 0.5rem 0.75rem
  position: relative
  .icon
    &:only-child
      margin-left: -0.25rem
      margin-right: -0.25rem

a.navbar-item,
.navbar-link
  cursor: pointer
  &:focus,
  &:focus-within,
  &:hover,
  &.is-active
    background-color: $navbar-item-hover-background-color
    color: $navbar-item-hover-color

.navbar-item
  flex-grow: 0
  flex-shrink: 0
  img
    max-height: $navbar-item-img-max-height
  &.has-dropdown
    padding: 0
  &.is-expanded
    flex-grow: 1
    flex-shrink: 1
  &.is-tab
    border-bottom: 1px solid transparent
    min-height: $navbar-height
    padding-bottom: calc(0.5rem - 1px)
    &:focus,
    &:hover
      background-color: $navbar-tab-hover-background-color
      border-bottom-color: $navbar-tab-hover-border-bottom-color
    &.is-active
      background-color: $navbar-tab-active-background-color
      border-bottom-color: $navbar-tab-active-border-bottom-color
      border-bottom-style: $navbar-tab-active-border-bottom-style
      border-bottom-width: $navbar-tab-active-border-bottom-width
      color: $navbar-tab-active-color
      padding-bottom: calc(0.5rem - #{$navbar-tab-active-border-bottom-width})

.navbar-content
  flex-grow: 1
  flex-shrink: 1

.navbar-link:not(.is-arrowless)
  +ltr-property("padding", 2.5em)
  &::after
    @extend %arrow
    border-color: $navbar-dropdown-arrow
    margin-top: -0.375em
    +ltr-position(1.125em)

.navbar-dropdown
  font-size: 0.875rem
  padding-bottom: 0.5rem
  padding-top: 0.5rem
  .navbar-item
    padding-left: 1.5rem
    padding-right: 1.5rem

.navbar-divider
  background-color: $navbar-divider-background-color
  border: none
  display: none
  height: $navbar-divider-height
  margin: 0.5rem 0

+until($navbar-breakpoint)
  .navbar > .container
    display: block
  .navbar-brand,
  .navbar-tabs
    .navbar-item
      align-items: center
      display: flex
  .navbar-link
    &::after
      display: none
  .navbar-menu
    background-color: $navbar-background-color
    box-shadow: 0 8px 16px bulmaRgba($scheme-invert, 0.1)
    padding: 0.5rem 0
    &.is-active
      display: block
  // Fixed navbar
  .navbar
    &.is-fixed-bottom-touch,
    &.is-fixed-top-touch
      +navbar-fixed
    &.is-fixed-bottom-touch
      bottom: 0
      &.has-shadow
        box-shadow: 0 -2px 3px bulmaRgba($scheme-invert, 0.1)
    &.is-fixed-top-touch
      top: 0
    &.is-fixed-top,
    &.is-fixed-top-touch
      .navbar-menu
        +overflow-touch
        max-height: calc(100vh - #{$navbar-height})
        overflow: auto
  html,
  body
    &.has-navbar-fixed-top-touch
      padding-top: $navbar-height
    &.has-navbar-fixed-bottom-touch
      padding-bottom: $navbar-height

+from($navbar-breakpoint)
  .navbar,
  .navbar-menu,
  .navbar-start,
  .navbar-end
    align-items: stretch
    display: flex
  .navbar
    min-height: $navbar-height
    &.is-spaced
      padding: $navbar-padding-vertical $navbar-padding-horizontal
      .navbar-start,
      .navbar-end
        align-items: center
      a.navbar-item,
      .navbar-link
        border-radius: $radius
    &.is-transparent
      a.navbar-item,
      .navbar-link
        &:focus,
        &:hover,
        &.is-active
          background-color: transparent !important
      .navbar-item.has-dropdown
        &.is-active,
        &.is-hoverable:focus,
        &.is-hoverable:focus-within,
        &.is-hoverable:hover
          .navbar-link
            background-color: transparent !important
      .navbar-dropdown
        a.navbar-item
          &:focus,
          &:hover
            background-color: $navbar-dropdown-item-hover-background-color
            color: $navbar-dropdown-item-hover-color
          &.is-active
            background-color: $navbar-dropdown-item-active-background-color
            color: $navbar-dropdown-item-active-color
  .navbar-burger
    display: none
  .navbar-item,
  .navbar-link
    align-items: center
    display: flex
  .navbar-item
    &.has-dropdown
      align-items: stretch
    &.has-dropdown-up
      .navbar-link::after
        transform: rotate(135deg) translate(0.25em, -0.25em)
      .navbar-dropdown
        border-bottom: $navbar-dropdown-border-top
        border-radius: $navbar-dropdown-radius $navbar-dropdown-radius 0 0
        border-top: none
        bottom: 100%
        box-shadow: 0 -8px 8px bulmaRgba($scheme-invert, 0.1)
        top: auto
    &.is-active,
    &.is-hoverable:focus,
    &.is-hoverable:focus-within,
    &.is-hoverable:hover
      .navbar-dropdown
        display: block
        .navbar.is-spaced &,
        &.is-boxed
          opacity: 1
          pointer-events: auto
          transform: translateY(0)
  .navbar-menu
    flex-grow: 1
    flex-shrink: 0
  .navbar-start
    justify-content: flex-start
    +ltr-property("margin", auto)
  .navbar-end
    justify-content: flex-end
    +ltr-property("margin", auto, false)
  .navbar-dropdown
    background-color: $navbar-dropdown-background-color
    border-bottom-left-radius: $navbar-dropdown-radius
    border-bottom-right-radius: $navbar-dropdown-radius
    border-top: $navbar-dropdown-border-top
    box-shadow: 0 8px 8px bulmaRgba($scheme-invert, 0.1)
    display: none
    font-size: 0.875rem
    +ltr-position(0, false)
    min-width: 100%
    position: absolute
    top: 100%
    z-index: $navbar-dropdown-z
    .navbar-item
      padding: 0.375rem 1rem
      white-space: nowrap
    a.navbar-item
      +ltr-property("padding", 3rem)
      &:focus,
      &:hover
        background-color: $navbar-dropdown-item-hover-background-color
        color: $navbar-dropdown-item-hover-color
      &.is-active
        background-color: $navbar-dropdown-item-active-background-color
        color: $navbar-dropdown-item-active-color
    .navbar.is-spaced &,
    &.is-boxed
      border-radius: $navbar-dropdown-boxed-radius
      border-top: none
      box-shadow: $navbar-dropdown-boxed-shadow
      display: block
      opacity: 0
      pointer-events: none
      top: calc(100% + (#{$navbar-dropdown-offset}))
      transform: translateY(-5px)
      transition-duration: $speed
      transition-property: opacity, transform
    &.is-right
      left: auto
      right: 0
  .navbar-divider
    display: block
  .navbar > .container,
  .container > .navbar
    .navbar-brand
      +ltr-property("margin", -.75rem, false)
    .navbar-menu
      +ltr-property("margin", -.75rem)
  // Fixed navbar
  .navbar
    &.is-fixed-bottom-desktop,
    &.is-fixed-top-desktop
      +navbar-fixed
    &.is-fixed-bottom-desktop
      bottom: 0
      &.has-shadow
        box-shadow: 0 -2px 3px bulmaRgba($scheme-invert, 0.1)
    &.is-fixed-top-desktop
      top: 0
  html,
  body
    &.has-navbar-fixed-top-desktop
      padding-top: $navbar-height
    &.has-navbar-fixed-bottom-desktop
      padding-bottom: $navbar-height
    &.has-spaced-navbar-fixed-top
      padding-top: $navbar-height + ($navbar-padding-vertical * 2)
    &.has-spaced-navbar-fixed-bottom
      padding-bottom: $navbar-height + ($navbar-padding-vertical * 2)
  // Hover/Active states
  a.navbar-item,
  .navbar-link
    &.is-active
      color: $navbar-item-active-color
    &.is-active:not(:focus):not(:hover)
      background-color: $navbar-item-active-background-color
  .navbar-item.has-dropdown
    &:focus,
    &:hover,
    &.is-active
      .navbar-link
        background-color: $navbar-item-hover-background-color

// Combination

.hero
  &.is-fullheight-with-navbar
    min-height: calc(100vh - #{$navbar-height})

// --END-- copy from Bulma to stop having errors


@include mobile
  .columns
    margin-left: 0
    margin-right: 0

.container.is-fluid
  padding-left: 25px
  padding-right: 25px

@include from($desktop)
  .columns.is-variable.is-10
    --columnGap: 4rem

@include until($desktop)
  .columns .column
    padding: 0.75rem 0

  .columns.is-variable
    margin-left: -0.75rem
    margin-right: -0.75rem

.select:not(.is-multiple):not(.is-loading)::after 
  border-color: #0B3954
  right: 1.125em
  z-index: 4
  border-width: 2px
  margin-top: 0px

b 
  font-weight: 500
  color: #0B3954

.dropdown-content
  padding: 15px 10px

.spinner 
  width: 50px
  height: 50px
  display: none
  position: fixed
  top: 30vh
  left: calc(50vw - 50px)


.user-dropdown
  .navbar-dropdown
    padding-top: 0 !important
    padding-bottom: 0 !important
    border-top: none
  .dropdown-content
    padding: 15px 0
    width: auto
    border-radius: 0px

  .dropdown-item
    margin-left: 0
    font-size: 16px
    padding: 0.375rem 1rem 0.375rem 45px
    position: relative

    i
      position: absolute
      top: 6px
      left: 15px
      font-size: 20px
      color: $primary
