@font-face {
  font-family: 'icomoon';
  src:  url('https://cdn.icomoon.io/210155/HealMe/icomoon.eot?t0a9jo');
  src:  url('https://cdn.icomoon.io/210155/HealMe/icomoon.eot?t0a9jo#iefix') format('embedded-opentype'),
  url('https://cdn.icomoon.io/210155/HealMe/icomoon.woff2?t0a9jo') format('woff2'),
  url('https://cdn.icomoon.io/210155/HealMe/icomoon.ttf?t0a9jo') format('truetype'),
  url('https://cdn.icomoon.io/210155/HealMe/icomoon.woff?t0a9jo') format('woff'),
  url('https://cdn.icomoon.io/210155/HealMe/icomoon.svg?t0a9jo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-start-booking:before {
  content: "\e9da";
  color: #0d9da4;
}
.icon-clients:before {
  content: "\e9de";
}
.icon-shopping-cart:before {
  content: "\e9f0";
  color: #c8d6df;
}
.icon-invite-clients:before {
  content: "\e9ef";
  color: #0b3954;
}
.icon-verification-green:before {
  content: "\e9f1";
  color: #0d9da4;
}
.icon-languages:before {
  content: "\e9ee";
  color: #55778a;
}
.icon-trust:before {
  content: "\e9ed";
  color: #0b3954;
}
.icon-payments-light:before {
  content: "\e9ec";
  color: #0b3954;
}
.icon-ellipsis:before {
  content: "\e9eb";
  color: #0d9da4;
}
.icon-portal-settings:before {
  content: "\e9ea";
  color: #0d9da4;
}
.icon-settings-payments:before {
  content: "\e9e6";
  color: #0b3954;
}
.icon-settings-booking:before {
  content: "\e9e7";
  color: #0b3954;
}
.icon-settings-personal:before {
  content: "\e9e8";
  color: #0b3954;
}
.icon-billing:before {
  content: "\e9e9";
  color: #0b3954;
}
.icon-home-filter:before {
  content: "\e9e3";
  color: #0b3954;
}
.icon-practice-filter:before {
  content: "\e9e4";
  color: #0b3954;
}
.icon-virtual-filter:before {
  content: "\e9e5";
  color: #0b3954;
}
.icon-new-client:before {
  content: "\e9e2";
  color: #0d9da4;
}
.icon-upload-2:before {
  content: "\e9e1";
  color: #0b3954;
}
.icon-notification:before {
  content: "\e9e0";
  color: #55778a;
}
.icon-pro-inbox:before {
  content: "\e9d6";
}
.icon-checkmark-filled:before {
  content: "\e9d5";
  color: #0d9da4;
}
.icon-clock-bold:before {
  content: "\e9df";
  color: #0b3954;
}
.icon-map-pin2:before {
  content: "\e9dd";
  color: #0b3954;
}
.icon-recurring:before {
  content: "\e9dc";
  color: #0b3954;
}
.icon-warning-icon:before {
  content: "\e9db";
  color: #eb5757;
}
.icon-discovery-phone:before {
  content: "\e9d9";
  color: #2966f0;
}
.icon-arrow-up:before {
  content: "\e9d8";
  color: #0d9da4;
}
.icon-current-location:before {
  content: "\e9d7";
  color: #0d9da4;
}
.icon-free-consultation:before {
  content: "\e9d4";
  color: #0d9da4;
}
.icon-high-rated:before {
  content: "\e9d3";
  color: #d09c51;
}
.icon-schedule-recurring:before {
  content: "\e9d0";
  color: #55778a;
}
.icon-schedule-one:before {
  content: "\e9d2";
  color: #55778a;
}
.icon-link-2:before {
  content: "\e9cf";
  color: #55778a;
}
.icon-payment-details:before {
  content: "\e9cc";
  color: #0d9da4;
}
.icon-smartphone:before {
  content: "\e9cd";
  color: #0d9da4;
}
.icon-copy-text:before {
  content: "\e9cb";
  color: #55778a;
}
.icon-discover:before {
  content: "\e9ca";
  color: #0b3954;
}
.icon-flip-camera:before {
  content: "\e9c8";
}
.icon-invite:before {
  content: "\e9c7";
}
.icon-Xls:before {
  content: "\e9c6";
  color: #0b3954;
}
.icon-envelope:before {
  content: "\e9c5";
  color: #0b3954;
}
.icon-external-link:before {
  content: "\e9c4";
  color: #0b3954;
}
.icon-dragndrop:before {
  content: "\e9c3";
  color: #55778a;
}
.icon-arrow-down-see-more:before {
  content: "\e9c2";
  color: #0b3954;
}
.icon-Endorsement:before {
  content: "\e9a7";
  color: #55778a;
}
.icon-client-directory:before {
  content: "\e906";
  color: #0b3954;
}
.icon-heart-circle:before {
  content: "\e900";
  color: #0b3954;
}
.icon-page:before {
  content: "\e904";
  color: #0b3954;
}
.icon-puzzle-piece:before {
  content: "\e905";
  color: #0b3954;
}
.icon-arrow-down:before {
  content: "\e99e";
  color: #0b3954;
}
.icon-arrow-left1:before {
  content: "\e99f";
  color: #0b3954;
}
.icon-arrow-right1:before {
  content: "\e9a0";
  color: #0b3954;
}
.icon-arrow-top:before {
  content: "\e9a1";
  color: #0b3954;
}
.icon-linkedin:before {
  content: "\e9a2";
  color: #0b3954;
}
.icon-camera1:before {
  content: "\e9a3";
  color: #0b3954;
}
.icon-arrow-right-long:before {
  content: "\e9a4";
  color: #0b3954;
}
.icon-attachment:before {
  content: "\e9a5";
  color: #55778a;
}
.icon-back:before {
  content: "\e9a6";
  color: #0b3954;
}
.icon-Calendar:before {
  content: "\e9a8";
  color: #0b3954;
}
.icon-chat:before {
  content: "\e9a9";
  color: #0b3954;
}
.icon-close-small:before {
  content: "\e9aa";
  color: #0b3954;
}
.icon-copy-link:before {
  content: "\e9ab";
  color: #0b3954;
}
.icon-done-small:before {
  content: "\e9ac";
  color: #27ae60;
}
.icon-forward:before {
  content: "\e9ad";
  color: #0b3954;
}
.icon-full-screen:before {
  content: "\e9ae";
  color: #0b3954;
}
.icon-happy-face:before {
  content: "\e9af";
  color: #55778a;
}
.icon-heart-filled:before {
  content: "\e9b0";
  color: #0b3954;
}
.icon-heart:before {
  content: "\e9b1";
  color: #0b3954;
}
.icon-link-small:before {
  content: "\e9b2";
  color: #0b3954;
}
.icon-link:before {
  content: "\e9b3";
  color: #0b3954;
}
.icon-map-pin .path1:before {
  content: "\e9b4";
  color: rgb(11, 57, 84);
}
.icon-map-pin .path2:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-map-pin1:before {
  content: "\e9c9";
}
.icon-menu-tablet:before {
  content: "\e9b6";
  color: #0b3954;
}
.icon-more:before {
  content: "\e9b7";
  color: #0b3954;
}
.icon-pin:before {
  content: "\e9b8";
  color: #0b3954;
}
.icon-plus:before {
  content: "\e9b9";
  color: #55778a;
}
.icon-send:before {
  content: "\e9ba";
  color: #55778a;
}
.icon-share-ios:before {
  content: "\e9bb";
  color: #0b3954;
}
.icon-snowflake:before {
  content: "\e9bc";
  color: #55778a;
}
.icon-star-filled:before {
  content: "\e9bd";
}
.icon-star:before {
  content: "\e9be";
  color: #0b3954;
}
.icon-thumb-up:before {
  content: "\e9bf";
  color: #0b3954;
}
.icon-time:before {
  content: "\e9c0";
  color: #0b3954;
}
.icon-chart:before {
  content: "\e901";
  color: #55778a;
}
.icon-chat-alt:before {
  content: "\e902";
  color: #55778a;
}
.icon-credit-card:before {
  content: "\e903";
  color: #55778a;
}
.icon-home-alt:before {
  content: "\e9c1";
  color: #55778a;
}
.icon-bookmark:before {
  content: "\e985";
  color: #0b3954;
}
.icon-arrow-left:before {
  content: "\e987";
}
.icon-arrow-right:before {
  content: "\e995";
}
.icon-calendar2:before {
  content: "\e986";
}
.icon-survey:before {
  content: "\e989";
}
.icon-female:before {
  content: "\e98a";
  color: #0b3954;
}
.icon-film:before {
  content: "\e98b";
  color: #0b3954;
}
.icon-love:before {
  content: "\e98c";
}
.icon-Menu:before {
  content: "\e98d";
}
.icon-message:before {
  content: "\e98e";
  color: #0b3954;
}
.icon-phone2:before {
  content: "\e98f";
}
.icon-location-pin:before {
  content: "\e990";
}
.icon-share:before {
  content: "\e991";
  color: #55778a;
}
.icon-squares:before {
  content: "\e992";
}
.icon-thumbs:before {
  content: "\e993";
}
.icon-user:before {
  content: "\e994";
}
.icon-booking-setup-banking:before {
  content: "\e984";
}
.icon-info1:before {
  content: "\e996";
}
.icon-price:before {
  content: "\e988";
}
.icon-warning:before {
  content: "\e983";
}
.icon-accept:before {
  content: "\e980";
}
.icon-booking-setup-options:before {
  content: "\e97e";
}
.icon-booking-setup-consultations:before {
  content: "\e97c";
}
.icon-booking-setup-locations:before {
  content: "\e97d";
}
.icon-menu:before {
  content: "\e97b";
  color: #0b3954;
}
.icon-appointments:before {
  content: "\e97a";
}
.icon-log-out:before {
  content: "\e979";
}
.icon-get-well:before {
  content: "\e971";
}
.icon-delete:before {
  content: "\e972";
}
.icon-match:before {
  content: "\e973";
}
.icon-answer:before {
  content: "\e974";
}
.icon-sign-up:before {
  content: "\e975";
}
.icon-login:before {
  content: "\e976";
}
.icon-reset-password:before {
  content: "\e977";
}
.icon-settings:before {
  content: "\e970";
  color: #55778a;
}
.icon-dashboard:before {
  content: "\e96f";
  color: #0b3954;
}
.icon-ask-question:before {
  content: "\e96e";
}
.icon-practitioner-search:before {
  content: "\e96d";
}
.icon-verified:before {
  content: "\e96c";
}
.icon-compass:before {
  content: "\e96b";
}
.icon-done:before {
  content: "\e922";
}
.icon-profile-setup-about:before {
  content: "\e952";
}
.icon-profile-setup-licenses:before {
  content: "\e953";
}
.icon-profile-setup-info:before {
  content: "\e954";
}
.icon-profile-setup-locations:before {
  content: "\e955";
}
.icon-profile-setup-media:before {
  content: "\e956";
}
.icon-profile-setup-options:before {
  content: "\e957";
}
.icon-profile-setup-skills:before {
  content: "\e958";
}
.icon-comments:before {
  content: "\e921";
}
.icon-facebook:before {
  content: "\e924";
}
.icon-helpful-filled:before {
  content: "\e925";
}
.icon-Instagram:before {
  content: "\e926";
}
.icon-phone:before {
  content: "\e927";
  color: #0b3954;
}
.icon-pinterest:before {
  content: "\e928";
}
.icon-play-filled:before {
  content: "\e929";
}
.icon-question:before {
  content: "\e92a";
}
.icon-quote:before {
  content: "\e92b";
}
.icon-rating-filled:before {
  content: "\e930";
}
.icon-twitter:before {
  content: "\e943";
}
.icon-virtual-filled:before {
  content: "\e947";
}
.icon-search:before {
  content: "\e920";
  color: #0b3954;
}
.icon-endorsement:before {
  content: "\e92c";
}
.icon-basic-info:before {
  content: "\e92d";
}
.icon-calendar:before {
  content: "\e92e";
}
.icon-camera:before {
  content: "\e92f";
}
.icon-profession:before {
  content: "\e931";
}
.icon-clock:before {
  content: "\e932";
}
.icon-close:before {
  content: "\e933";
  color: #0b3954;
}
.icon-dropdown:before {
  content: "\e934";
}
.icon-edit:before {
  content: "\e935";
  color: #0b3954;
}
.icon-filters:before {
  content: "\e936";
}
.icon-hamburger:before {
  content: "\e937";
}
.icon-helpful:before {
  content: "\e938";
}
.icon-instagram:before {
  content: "\e939";
}
.icon-license:before {
  content: "\e93a";
}
.icon-location:before {
  content: "\e93b";
}
.icon-map:before {
  content: "\e93c";
}
.icon-media:before {
  content: "\e93d";
}
.icon-minus:before {
  content: "\e93e";
}
.icon-play:before {
  content: "\e93f";
  color: #0d9da4;
}
.icon-create:before {
  content: "\e940";
}
.icon-profile:before {
  content: "\e941";
}
.icon-rating:before {
  content: "\e942";
}
.icon-shevron:before {
  content: "\e944";
}
.icon-upload:before {
  content: "\e945";
}
.icon-virtual:before {
  content: "\e946";
}
.icon-permissions:before {
  content: "\e95c";
}
.icon-administrator-salesperson:before {
  content: "\e91b";
}
.icon-administrator-support:before {
  content: "\e91c";
}
.icon-administrator-developer:before {
  content: "\e91d";
}
.icon-administrator-owner:before {
  content: "\e91e";
}
.icon-secure:before {
  content: "\e97f";
}
.icon-background-events-queued:before {
  content: "\e969";
}
.icon-background-events-failed:before {
  content: "\e966";
}
.icon-background-events-completed:before {
  content: "\e967";
}
.icon-background-events-started:before {
  content: "\e968";
}
.icon-error:before {
  content: "\e94f";
}
.icon-info:before {
  content: "\e909";
}
.icon-cache-plunge:before {
  content: "\e96a";
}
.icon-approve:before {
  content: "\e960";
}
.icon-filter:before {
  content: "\e95f";
}
.icon-impersonate:before {
  content: "\e95b";
}
.icon-reject:before {
  content: "\e951";
}
.icon-admin-edit:before {
  content: "\e94c";
}
.icon-checked:before {
  content: "\e913";
  color: #000;
}
.icon-spinner:before {
  content: "\e912";
}
.icon-save:before {
  content: "\e911";
}
.icon-trash:before {
  content: "\e90f";
}
.icon-cancel:before {
  content: "\e910";
}
.icon-right:before {
  content: "\e90b";
}
.icon-left:before {
  content: "\e90c";
}
.icon-double-right:before {
  content: "\e90d";
}
.icon-double-left:before {
  content: "\e90e";
}
.icon-add:before {
  content: "\e908";
}
.icon-logout:before {
  content: "\e907";
}
.icon-admin-search:before {
  content: "\e90a";
}
.icon-refunds:before {
  content: "\e982";
}
.icon-transactions:before {
  content: "\e981";
}
.icon-quiz-records:before {
  content: "\e978";
}
.icon-cache:before {
  content: "\e963";
}
.icon-background-engine:before {
  content: "\e964";
}
.icon-control-board:before {
  content: "\e965";
}
.icon-sms:before {
  content: "\e961";
}
.icon-email:before {
  content: "\e962";
  color: #0b3954;
}
.icon-states:before {
  content: "\e95d";
}
.icon-countries:before {
  content: "\e95e";
}
.icon-locations:before {
  content: "\e95a";
}
.icon-issuers:before {
  content: "\e959";
}
.icon-certifications:before {
  content: "\e923";
}
.icon-tag:before {
  content: "\e950";
}
.icon-unpublish:before {
  content: "\e94d";
}
.icon-publish:before {
  content: "\e94e";
}
.icon-published:before {
  content: "\e94b";
}
.icon-questions:before {
  content: "\e949";
}
.icon-articles:before {
  content: "\e94a";
}
.icon-contentbank:before {
  content: "\e948";
}
.icon-specialties:before {
  content: "\e91f";
}
.icon-practitioners:before {
  content: "\e914";
}
.icon-professions:before {
  content: "\e915";
}
.icon-localities:before {
  content: "\e916";
}
.icon-zip-codes:before {
  content: "\e917";
}
.icon-insurances:before {
  content: "\e918";
}
.icon-payment-methods:before {
  content: "\e919";
}
.icon-features:before {
  content: "\e91a";
}
.icon-fields:before {
  content: "\e997";
}
.icon-leads:before {
  content: "\e998";
}
.icon-inbox:before {
  content: "\e999";
}
.icon-administrators:before {
  content: "\e99a";
}
.icon-analytics:before {
  content: "\e99b";
}
.icon-consumers:before {
  content: "\e99c";
}
.icon-databank:before {
  content: "\e99d";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
.icon-pause2:before {
  content: "\ea1d";
}
.icon-facebook2:before {
  content: "\ea91";
}
