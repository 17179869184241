body.has-navbar-fixed-top
  padding-top: 78px !important
  background-color: #FFF
  @include mobile
    padding-top: 58px !important

.navbar
  border-bottom: 1px solid #E4EFF6
  min-height: 80px
  .navbar-container
    display: flex
    width: 100%

  .navbar-mobile-menu
    height: 50px
    width: 50px
    flex-grow: 2

  @include mobile
    min-height: 64px
    padding: 0
    border-bottom: 1px solid $grey-light
    display: flex
    align-items: center
    justify-content: space-between

  .navbar-brand
    @include h3style

    a.navbar-item
      margin-top: -3px
      padding-top: 0
      padding-left: 0
      padding-bottom: 0

      @include mobile
        padding: 0.75rem 0px 0.75rem 22px
        font-size: 22px

  .navbar-list-practice
    @media (min-width: 1024px)
      display: none
    .button-tertiary
      background: #0B3954
      border-radius: 4px !important
      font-weight: 500 !important
      font-size: 14px !important
      line-height: 16px
      color: #FFF !important
      padding: 10px 16px 10px 16px !important
      border: none
      width: auto !important
      margin-right: 20px
      text-transform: none !important

  .navbar-menu
    @include mobile
      display: none

    &.navbar-fill
      margin-bottom: -20px
      min-width: 275px
      max-width: 275px

    .navbar-start
      flex-grow: 1
      flex-shrink: 1
      display: flex

      .input-wrapper
        position: relative
        width: 100%
        margin: 0 40px

        @include mobile
          margin: 0 20px

        input[type='text']
          font-size: 16px
          padding: 19px 45px 17px 20px
          height: auto
          border: 1px solid #dbdbdb
          border-radius: 12px
          color: $black
          width: 100%

          &:focus
            border-color: $primary
            outline: none

        i
          position: absolute
          z-index: 1
          font-size: 24px
          color: $black
          right: 15px
          top: 15px

    .navbar-therapies
      align-items: stretch
      padding-left: 30px
      @media (max-width: 1299px)
        padding-left: 0
      @include mobile
        display: none
      .navbar-fill-height
        height: 75px
      a.navbar-item
        font-weight: bold
        font-size: 17px
        line-height: 16px
        color: #0B3954

    .navbar-end
      align-items: center
      .button-tertiary
        background: #0B3954
        border-radius: 4px
        font-weight: 700
        font-size: 16px
        line-height: 16px
        color: #FFF !important
        padding: 22px
        text-transform: none
        border: none
        width: auto
        &:hover
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #0B3954
      a.pro-page
        color: #0B3954
        font-weight: 700
        font-size: 17px
        border: 1px solid #C8D6DF
        padding: 10px 16px
        border-radius: 50px
        font-family: inherit

        i
          padding-right: 8px

      a.header-inbox
        font-weight: normal
        font-size: 30px
        margin-right: 40px
        position: relative
        display: flex

        i
          font-weight: 100
          border: 1px solid #C8D6DF
          border-radius: 50px
          padding: 11px 14px
          font-size: 25px

        &:hover
          text-decoration: none
      .navbar-signin
        font-weight: bold
        font-size: 17px
        line-height: 16px
        color: #0B3954
        margin-right: 25px
        &:hover
          text-decoration: underline
          background-color: transparent
          cursor: pointer

        @include mobile
          font-size: 15px

      i
        font-weight: 600
        top: 8px

      @include mobile
        margin: 1.5rem 0

      @include mobile
        margin: 0.5rem 0 0.5rem 20px

        i
          left: 0px !important
          top: 7px
          color: #0B3954
          font-weight: 600

      &.has-dropdown
        padding-left: 10px

      .navbar-view-profile
        @include mobile
          display: none
        margin-right: 30px
        .btn-tertiary
          height: 44px !important
          font-weight: 500 !important
          text-decoration: none !important
          i.icon-external-link
            margin-top: -1.5px
            font-size: 16px

    .navbar-link
      padding: 0 20px 0 0
      background-color: transparent !important
      margin-left: 15px
      .image-wrapper
        border-radius: 50%
        background: white
        border: 1px solid $primary
        display: inline-block
        width: 50px
        height: 50px

        img
          margin-left: 2px
          margin-top: 2px
          width: 44px
          height: 44px
          border-radius: 50%
          max-height: initial

      &:after
        right: 0px
        border-width: 1px
        margin-top: -0.5rem

    .is-active .navbar-link .image-wrapper
      border-color: $contrast

    .navbar-link
      @include mobile
        display: none

    .navbar-link:hover .image-wrapper
      border-color: $contrast

    .navbar-link:hover:after
      border-color: $contrast

    .navbar-dropdown
      padding: 10px 0
      width: auto
      right: 0
      left: auto
      top: calc(100% + 5px)

      .navbar-item
        padding: 10px 50px 10px 60px
        margin-left: 0
        font-size: 16px

        i
          top: 10px
          left: 30px
          font-size: 20px
          color: $primary

    @include mobile
      .navbar-dropdown
        padding: 0 !important
        margin: 0 !important

      .has-dropdown
        padding: 0 !important
        margin: 0 !important

        .navbar-item
          padding: 0.5rem 0.75rem 0.5rem 30px
          margin: 0.5rem 0 0.5rem 20px

          i
            left: 0px
            top: 7px

    a.button.login
      margin-left: 1rem !important
      font-size: 14px
      font-weight: 400
      padding: 14px 25px 14px 25px !important
      margin-top: 4px !important
      top: -2px
      max-height: 49px !important

      &:hover
        transform: scale(1.1)

      @include mobile
        margin-left: 0 !important
        max-width: 200px
