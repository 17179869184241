@mixin h2style
  font-family: 'Manrope', serif
  font-size: 30px
  font-weight: 700

@mixin h3style
  font-family: 'Manrope', serif
  font-size: 26px
  font-weight: 500
  color: $black

@mixin h4style
  font-family: 'Manrope', serif
  font-size: 20px
  font-weight: 500
  color: $black

@mixin h5style
  color: $black
  font-size: 18px
  font-weight: 400

@mixin h6style
  color: $black
  font-size: 16px
  font-weight: 500

@mixin headingfont
  font-family: 'Manrope', serif

@mixin subheading
  color: $black-light
  text-transform: uppercase
  letter-spacing: 0.5px
  font-size: 14px
  font-weight: 400

@mixin homepage-heading
  font-family: 'Manrope', sans-serif
  font-weight: 600
  color: #0B3954

@mixin homepage-standard
  font-family: inherit
  font-weight: 400
  color: #0B3954

h1
  font-family: 'Manrope', serif
  font-size: 48px
  font-weight: 700
  color: $black

  &.success
    color: $success

  @include until($desktop)
    font-size: 36px

h2
  @include h2style
  margin-bottom: 0.4rem

  @include until($desktop)
    font-size: 25px

h3
  @include h3style
  margin-bottom: 0.4rem

h4
  @include h4style
  margin-bottom: 0.4rem

h5
  @include h5style
  margin-bottom: 1rem

h6
  @include h6style
  margin-bottom: 1rem
